<template>
  <div v-if="permission.access">
    <b-card no-body v-if="permission.create || !form.id">
      <b-card-body>
        <form @submit.prevent="save()" @keydown="form.onKeydown($event)">
          <b-row>
            <b-col lg="12">
              <b-row>
                <b-col cols="12" lg="5">
                  <div class="form-group">
                    <label>District*</label>
                    <Select
                        v-model="form.district_id"
                        placeholder="Select District Name"
                        type="text"
                        filterable
                    >
                      <Option
                          v-if="allDistricts.length"
                          v-for="(district, index) in allDistricts"
                          :key="index"
                          :value="district.id"
                      >
                        {{ district.name }}
                      </Option>
                    </Select>
                    <has-error
                        :form="form"
                        field="district_id"
                    ></has-error>
                  </div>
                </b-col>

                <b-col cols="12" lg="5">
                  <div class="form-group">
                    <label>Name*</label>
                    <Input
                        v-model="form.name"
                        placeholder="Enter Upazila's Name"
                        type="text"
                    />
                    <has-error
                        :form="form"
                        field="name"
                    ></has-error>
                  </div>
                </b-col>

                <b-col cols="12" lg="2">
                  <div class="form-group">
                    <b-row class="pt-1 text-right">
                      <b-col>
                        <b-button
                            variant="primary"
                            type="submit"
                            :disabled="form.busy"
                        >
                          {{ form.id ? 'Update' : 'Add' }}
                        </b-button>
                        <b-button
                            variant="danger"
                            class="ml-1"
                            @click="clear()"
                        >
                          Clear
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </form>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-body>
        <h5 class="mb-2">Upazila Lists</h5>
        <b-row class="justify-content-between">
          <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1">Show</label>
            <Select
                @on-change="paginateChange"
                v-model="search.paginate"
                :clearable="false"
                style="width: 80px"
            >
              <Option :value="10"> 10</Option>
              <Option :value="30"> 30</Option>
              <Option :value="50"> 50</Option>
              <Option :value="80"> 80</Option>
              <Option :value="100"> 100</Option>
            </Select>
          </b-col>
          <b-col cols="12" md="3">
            <Input
                v-model="search.search_data"
                class="d-inline-block mr-1"
                placeholder="Search name..."
                type="text"
            />
          </b-col>
          <b-col cols="12" md="3">
            <Select
                @on-change="SearchData()"
                v-model="search.district_id"
                placeholder="Select District"
                filterable
                :clearable="true"
            >
              <Option
                  v-if="allDistricts.length"
                  v-for="(district, index) in allDistricts"
                  :key="index"
                  :value="district.id"
              >{{ district.name }}
              </Option>
            </Select>
          </b-col>
          <b-col cols="12" md="3">
            <Select
                @on-change="SearchData()"
                v-model="search.division_id"
                placeholder="Select Division"
                filterable
                :clearable="true"
            >
              <Option
                  v-if="divisions.length"
                  v-for="(division, index) in divisions"
                  :key="index"
                  :value="division.id"
              >{{ division.name }}
              </Option>
            </Select>
          </b-col>
        </b-row>
      </b-card-body>
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th class="text-center">Sl.</th>
            <th>Name</th>
            <th>District</th>
            <th>Created At</th>
            <th v-if="permission.edit || permission.delete" class="text-center">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(upazila, index) in upazilas.data" :key="index" v-if="upazilas.data.length">
            <th class="text-center">{{ upazilas.from + index }}</th>
            <td>{{ upazila.name }}</td>
            <td>
              <span v-if="upazila.district">
                {{ upazila.district.name }}
              </span>
              <span v-else style="color: red">No district exists.</span>
            </td>
            <td>{{ upazila.created_at | date_time_format }}</td>
            <td v-if="permission.edit || permission.delete" class="text-center">
              <button
                  v-if="permission.edit"
                  class="btn btn-primary btn-sm"
                  @click="edit(upazila.id)"
              >
                <feather-icon icon="EditIcon"/>
              </button>
              <button
                  v-if="permission.delete"
                  class="btn btn-danger btn-sm ml-1"
                  @click="destroy(upazila.id)"
              >
                <feather-icon icon="TrashIcon"/>
              </button>
            </td>
          </tr>

          </tbody>
        </table>
        <b-card-body class="text-center">
          <b class="text-center" v-if="upazilas.data.length==0"> No data found</b>
          <div class="d-flex justify-content-between align-items-center">
                <span class="text-muted"
                >Showing {{ upazilas.from }} to {{ upazilas.to }} of
                  {{ upazilas.total }} entries</span
                >
            <pagination
                class="mb-0"
                :data="upazilas"
                @pagination-change-page="getResults"
                align="right"
                :show-disabled="true"
                :limit="5"
            ></pagination>
          </div>
        </b-card-body>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardBody
} from "bootstrap-vue";
import {HasError, AlertError} from "vform/src/components/bootstrap5";
import _ from "lodash";

export default {
  props: [],
  data() {
    return {
      form: new Form({
        id: null,
        name: null,
        bn_name: null,
        url: null,
      }),
      search: {
        district_id: "",
        division_id: "",
        search_data: "",
        status: "",
        paginate: 10,
        page: 1,
      },
    };
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    AlertError,
    BCard,
    BCardBody
  },
  mounted() {
    this.getResults();
    this.$store.dispatch("getAllDistrict");
    this.$store.dispatch("getDivision");
  },
  computed: {
    allDistricts() {
      return this.$store.getters.getAllDistrict;
    },
    upazilas() {
      return this.$store.getters.getUpazila;
    },
    divisions() {
      return this.$store.getters.getDivision;
    },
    permission() {
      return this.$store.getters.getPermissions.upazila;
    },
  },
  methods: {
    buildQueryParamString(search) {
      const queryParams = [];
      for (const key in search) {
        if (search[key] !== undefined && search[key] !== "") {
          queryParams.push(`${key}=${encodeURIComponent(search[key])}`);
        }
      }
      return queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
    },
    getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const queryParams = this.buildQueryParamString(this.search);
      this.$store.dispatch("getUpazila", queryParams);
    },
    clear() {
      this.form.id = null;
      this.form.name = null;
      this.form.district_id = null;
    },
    save() {
      if (!this.form.id) {
        this.form.post('/app/upazila/')
            .then(response => {
              this.s(response.data.message);
              this.clear();
              this.getResults();
            })
            .catch((e) => {
              this.e(e.data.error);
            });
      } else {
        this.form.put('/app/upazila/' + this.form.id)
            .then(response => {
              this.s(response.data.message);
              this.clear();
              this.getResults();
            })
            .catch((e) => {
              this.e(e.data.error);
            });
      }
    },
    edit(id) {
      axios
          .get("/app/upazila/" + id + "/edit")
          .then((res) => {
            this.form.id = res.data.upazila.id;
            this.form.name = res.data.upazila.name;
            this.form.district_id = res.data.upazila.district_id;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    Delete(id) {
      axios
          .delete("/app/upazila/" + id)
          .then((response) => {
            this.s(response.data.message);
            this.getResults();
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    destroy(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.Delete(id);
        }
      });
    },
    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  watch: {
    "search.search_data": function () {
      this.SearchData();
    },
    "search.paginate": function () {
      this.paginateChange();
    },
  },
};
</script>

<style scoped>
</style>